/**
 * @author Kinji Shimizu 
 * @created 05/10/2024
 * @lastModified 06/02/2024
 * @description App
 * @copyright  studioARATA
 */

import React, { useEffect, useState, useCallback } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import initializeApp from "./app/init";
import CssBaseline from '@mui/material/CssBaseline';
import i18n from './i18n';
import "@fontsource/noto-sans-jp";
import "@fontsource/noto-sans-jp/400.css";
import AppRouter from './router/AppRouter';
import Header from './components/header';
import Footer from './components/footer';
import { I18nextProvider } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import "./global.css";
import axios from 'axios';
import Container from './components/Container';
import { useDispatch } from 'react-redux';
import { getUser } from './store/slices/userSlice';
import { setProducts, setCategories, setRequirements, setCreators, setUnreadMessages, setClients, setCartLength } from './store/slices/commonSlice';

const App = () => {

    initializeApp();
    const dispatch = useDispatch();

    const toggleLanguage = useCallback(() => {
        const currentLanguage = i18n.language;
        const newLanguage = currentLanguage === 'en' ? 'jp' : 'ch';
        i18n.changeLanguage(newLanguage);
    }, [i18n.language]);

    const loadData = useCallback(async () => {
        try {
            const [products, categories, creators, clients, requirements] = await Promise.all([
                axios.get("/products"),
                axios.get("/categories"),
                axios.get("/creators"),
                axios.get("/clients"),
                axios.get("/requirement")
            ]);

            dispatch(setRequirements(requirements.data));
            dispatch(setCreators(creators.data));
            dispatch(setProducts(products.data));
            dispatch(setClients(clients.data));
            dispatch(setCategories(categories.data));
        } catch (error) {
            console.error("Error loading data:", error);
        }
    }, [dispatch]);

    const loadCart = useCallback(() => {
        axios.get("/cart")
            .then(response => {
                dispatch(setCartLength(response?.data?.cart?.items?.length || 0));
            })
            .catch(error => {
                console.error("Error loading cart:", error.response || error);
            });
    }, [dispatch]);

    const fetchUnreadMessages = useCallback(() => {
        axios.get(`/unread-messages`)
            .then(response => {
                dispatch(setUnreadMessages(response?.data?.unreadMessages || 0));
            })
            .catch(error => {
                console.error("Error fetching unread messages:", error.response || error);
            });
    }, [dispatch]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            try {
                dispatch(getUser(token));
            } catch (error) {
                console.error("Error getting user:", error);
                window.location.href = "/login";
            }
        }
        loadData();
    }, [dispatch, loadData]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            loadCart();
            fetchUnreadMessages();
            const interval = setInterval(fetchUnreadMessages, 10000);
            return () => clearInterval(interval);
        }
    }, [loadCart, fetchUnreadMessages]);

    return (
        <HelmetProvider>
            <CssBaseline />
            <I18nextProvider i18n={i18n}>
                <Router>
                    <Header />
                    <Container>
                        <AppRouter />
                    </Container>
                    <Footer />
                </Router>
            </I18nextProvider>
            <ToastContainer />
        </HelmetProvider>
    );
};

export default App;
