import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo/LOGO.png';



const TopBar = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user);

    const handleLogin = () => {
        navigate('/auth/login');
    }

    const pagetitle = "VRTUAL FASHION MALL に クリエイター または、ユーザーとして登録する"

    return (
        <>
            {!user ? 
            <div className="flex flex-col md:justify-between md:flex-row pr-10  h-b-1">
                <div className='p-3 flex flex-row items-center justify-start'>
                    <img className="logo-width" onClick={() => navigate('/')} src={logo} alt="Logo" />
                    <div className="space-div"></div>
                    <p className="tracking-wide">VIRTUAL&nbsp; FASHION&nbsp; MALL</p>
                </div>
                <button onClick={handleLogin} className='bg-blue-600 text-white w-auto m-2 px-6 py-1 hover:bg-blue-500 whitespace-nowrap rounded-sm'>クリエイター/ユーザーとして登绿する</button>
            </div> :
            <div className='p-3 flex flex-row items-center justify-start h-b-1'>
                <img className="logo-width" onClick={() => navigate('/')} src={logo} alt="Logo" />
                <div className="space-div"></div>
                <p className="tracking-wide">VIRTUAL&nbsp; FASHION&nbsp; MALL</p>
            </div>
            }
        </>
    )
}

export default TopBar;
